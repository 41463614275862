import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { useStaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import options from '../../templates/richTextRenderers';
import componentFinder from '../util/componentFinder';

const RichText = props => {
  const data = props.data[props.componentId];

  let bgcolor =
    data.backgroundColor === 'dark' ? 'secondary.main' : '';

  return (
    <Box bgcolor={bgcolor} py={5}>
      <Container maxWidth="md">
        <Typography
          variant="h1"
          component="h2"
          gutterBottom
          align="center"
        >
          {data.title}
        </Typography>
        <Typography
        variant="h6"
        component="h3"
        gutterBottom
        align="center"
      >
        {data.subtitle}
      </Typography>

        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="center"
        >
          <Grid item sm={12} md={12} lg={12}>
            <Typography component="span" variant="h6">
              {documentToReactComponents(data.text.json, options())}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default RichText;
